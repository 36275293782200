import axios from 'axios';

import qs from 'qs';

// 配置新建一个 axios 实例
const service = axios.create({
	baseURL: process.env.baseURL,
	timeout: 200000,
	paramsSerializer: {
		serialize(params) {
			return qs.stringify(params, { allowDots: true });
		},
	},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// // 在发送请求之前做些什么 token
		// if (Session.get('token')) {
		// 	config.headers['Authorization'] = `${Session.get('token')}`;
		// }
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		if (response.config.url === '/login') {
			//Session.set('token', response.data.token)
		} else {
			const res = response.data;
			if (res.errCode === 401) {
				window.location.href = '/login';
			} else {
				return res;
			}
		}
	},
	(error) => {
		console.log(error.message);
		return Promise.reject(error);
	}
);

// 导出 axios 实例
export default service;
