
import request from '@/utils/request';



class ConfigApi {

    brokerages = () =>{

        return request({
            url: '/adm/config/brokerage/brokerage_list',
            method: 'post',
           
        });	

    }

    account_list =() => {
        return request({
            url: '/adm/config/bank/bank_account_list',
            method: 'post', 
        });	
    }

    account_setup=(data) => {
        return request({
            url: '/adm/config/bank/bank_account_setting',
            method: 'post', 
            data
        });	
    }
    

    setup_trust_term_account =(data) => {
        return request({
            url: '/adm/config/bank/setup_account',
            method: 'post', 
            data
        });	

        
    }

   

    cheque_list = (data) => {
        return request({
            url: '/adm/config/cheque/cheque_list',
            method: 'post', 
            data
        });	
    }

    cheque_setup = (data) => {
        return request({
            url: '/adm/config/cheque/cheque_setup',
            method: 'post', 
            data
        });	
    }

    cheque_discard = (data) => {
        return request({
            url: '/adm/config/cheque/cheque_discard',
            method: 'post', 
            data
        });	
    }


    fee_list = (data) => {
        return request({
            url: '/adm/config/brokerage/fees',
            method: 'post', 
            data
        });	
        
    }

    setup_new_fee = (data) => {
        return request({
            url: '/adm/config/brokerage/setup_new_fee',
            method: 'post', 
            data
        });	
    }

    fee_update = (data) => {
        return request({
            url: '/adm/config/brokerage/fee_conf_update',
            method: 'post', 
            data
        });	
    }


    expense_fee_list = (data) => {
        return request({
            url: '/adm/config/brokerage/expense_fee_conf_list',
            method: 'post', 
            data
        });	
    }



    save_brokerage_configuration = (data) => {
        return request({
            url: '/adm/config/brokerage/save_brokerage_config',
            method: 'post', 
            data
        });	
        
    }

    brokerage_config = () =>{
        
        return request({
            url: '/adm/config/brokerage/configs',
            method: 'post', 
        });	
    }


    agent_default_fees = (data) => {
        return request({
            url: '/adm/config/brokerage/default_agent_fee_items',
            method: 'post', 
            data
        });	
        
    }


    brokerage_supplier_list = (data) => {
        return request({
            url: '/adm/config/supplier/supplier_list',
            method: 'post', 
            data
        });	
        
    }

    supplier_update = (data) => {
        return request({
            url: '/adm/config/supplier/supplier_save_update',
            method: 'post', 
            data
        });	
        
    }



    holiday_list = (data) => {
        return request({
            url: '/adm/config/brokerage/satautory_holidays_list',
            method: 'post', 
            data
        });	
    }

    holiday_add = (data) => {
        return request({
            url: '/adm/config/brokerage/satautory_holiday_add',
            method: 'post', 
            data
        });	
    }

    holiday_remove = (data) => {
        return request({
            url: '/adm/config/brokerage/satautory_holiday_remove',
            method: 'post', 
            data
        });	
    }


    gl_config = (data) => {
        return request({
            url: '/adm/config/brokerage/gl_config',
            method: 'post', 
            data
        });	
    } 
    gl_config_udpate = (data) => {
        return request({
            url: '/adm/config/brokerage/gl_config_udpate',
            method: 'post', 
            data
        });	
    } 


    pm_trust_bank_account = (data) => {
        return request({
            url: '/adm/config/bank/pm_trust_bank_account',
            method: 'post', 
            data
        });	

    }


    deal_type_list = (data) => {
        return request({
            url: '/adm/config/brokerage/list_deal_type_config',
            method: 'post', 
            data
        });	
    }

    update_deal_type = (data) => {
        return request({
            url: '/adm/config/brokerage/add_or_update_deal_type',
            method: 'post', 
            data
        });	
    }

    remove_deal_type = (data) => {
        return request({
            url: '/adm/config/brokerage/remove_deal_type',
            method: 'post', 
            data
        });	
    }

    export_fee_conf = (data) => {

        return request({
            url: '/adm/config/brokerage/export_default_fees',
            method: 'post', 
            data
        });	
        
    }


    
}

class OfficeApi {
    

    list = (data) => {

        return request({
            url: '/adm/brokerage/office/office_list',
            method: 'post',
            data
        });	
    }

    office_edit = (data) => {
        return request({
            url: '/adm/brokerage/office/office_edit',
            method: 'post',
            data
        });	
    }

    role_list = () => {
        return request({
            url: '/adm/brokerage/office/office_role',
            method: 'post',
          
        });	
    }

    team_list = () => {
        return request({
            url: '/adm/brokerage/agent/team/team_list',
            method: 'post',
        });	
    }

    team_edit = (data) => {
        return request({
            url: '/adm/brokerage/agent/team/edit_agent_team',
            method: 'post',
            data
        });	
    }

    team_create = (data) => {
        return request({
            url: '/adm/brokerage/agent/team/setup_new_team',
            method: 'post',
            data
        });	
    }

    team_info = (data) => {
        return request({
            url: '/adm/brokerage/agent/team/team_detail',
            method: 'post',
            data
        });	
        
    }


    team_delete = (data) => {
        return request({
            url: '/adm/brokerage/agent/team/team_delete',
            method: 'post',
            data
        });	
        
    }
}


class StaffApi {
    list = (data) => {
        return request({
            url: '/adm/brokerage/staff/staff_list',
            method: 'post',
            data
        });	
    }

    create_new_staff = (data) => {
        return request({
            url: '/adm/brokerage/staff/staff_create',
            method: 'post',
            data
        });	
    }

    modify_staff=(data) => {
        return request({
            url: '/adm/brokerage/staff/staff_edit',
            method: 'post',
            data
        });	
    }

    delete_staff =(data) => {
        return request({
            url: '/adm/brokerage/staff/staff_remove',
            method: 'post',
            data
        });	
    }
}


class AgentApi {
    list = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_list',
            method: 'post',
            data
        });	
    }

    edit_agent = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_edit',
            method: 'post',
            data
        });	
    }

    agent_detail = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_detail',
            method: 'post',
            data
        });	
    }

    agent_bill = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_bill_query',
            method: 'post',
            data
        });	
    }

    agent_commission = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_commission_query',
            method: 'post',
            data
        });	
        
    }

    agent_create = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_create',
            method: 'post',
            data
        });	
    }


    agent_add_fee = (data) => {
        return request({
            url: '/adm/brokerage/agent/fee/agent_fee_add_items',
            method: 'post',
            data
        });	
    }
    

    agent_remove_fee = (data) => {
        return request({
            url: '/adm/brokerage/agent/fee/agent_fee_remove',
            method: 'post',
            data
        });	
    }

    fee_update_only = (data) => {
        return request({
            url: '/adm/brokerage/agent/fee/agent_fee_amount_update',
            method: 'post', 
            data
        });	
    }

    agent_fees = (data) => {
        return request({
            url: '/adm/brokerage/agent/fee/agent_fees',
            method: 'post',
            data
        });	
    }


    
}


class DocApi {
    folderTree = () => {
        return request({
            url: '/adm/brokerage/document/folder_tree',
            method: 'post',
            
        });	
    }

    folderSave = (data) => {
        return request({
            url: '/adm/brokerage/document/folder_create',
            method: 'post',
            data
        });	
    }

    filesSave =(data) => {
        return request({
            url: '/adm/brokerage/document/files_save',
            method: 'post',
            data
        });	
    }

    filesDelete =(data) => {
        return request({
            url: '/adm/brokerage/document/files_delete',
            method: 'post',
            data
        });	
    }

    fileFolderRename =(data) => {
        return request({
            url: '/adm/brokerage/document/file_folder_rename',
            method: 'post',
            data
        });	
    }
}



let _officeApi   = null;
let _staffApi    = null;
let _agentApi    = null;
let _configApi   = null;
let _docsApi     = null;


let brokerageApi = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initBrokerageApi = () => {
    if (!_officeApi) {
        _officeApi = new OfficeApi();
    }

    if (!_staffApi) {
        _staffApi = new StaffApi();
    }

    if (!_agentApi) {
        _agentApi = new AgentApi();
    }

    if (!_configApi) {
        _configApi = new ConfigApi()
    }

    if (!_docsApi) {
        _docsApi = new DocApi()
    }
    if (!brokerageApi) {
        brokerageApi = {
            officeApi : _officeApi,
            staffApi : _staffApi,
            agentApi : _agentApi,
            configApi : _configApi,
            docApi : _docsApi
        }
    }
    return brokerageApi;
}

/**
 * Returns the firebase backend
 */
const getBrokerageApi = () => {
    return brokerageApi;
}

export { initBrokerageApi, getBrokerageApi };


