
import request from '@/utils/request';





const signIn =(data) => {
	return request({
		url: '/adm/user/login',
		method: 'post',
		data
	});	
}

const smsCode =(data) => {
	return request({
		url: '/adm/user/code',
		method: 'post',
		data
	});
}

export {signIn, smsCode}
